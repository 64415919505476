<template>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
<!--            <Sidepanel status="pending-ext-files" @onDriverSelected="onDriverSelected" />-->
            <div class="">
<!--              <Toolbar />-->
              <template>

                <div>
                  <div class="mt-0">
                    <h5 class="font-18">
<!--                      <span class="mr-3">Attachments for {{driver.name}} <span class="badge badge-danger" v-if="driver['driver']['dossier']">Dossier: {{driver['driver']['dossier']}}</span> <span class="badge badge-danger" v-else>Dossier: N/A</span></span>-->
                    </h5>
                    <hr>
                    <div class="row">
                      <div class="col-md-2">
                        <div class="form-group">
                          <label>Driver</label>
                          <multiselect v-model="selectedDrivers" :options="drivers" label="name" track-by="name" :multiple="true"></multiselect>

                        </div>
                      </div>

                      <div class="col-md-2">
                        <div class="form-group">
                          <label>Invoice no.</label>
                          <b-input id="inline-form-input-name" class="" @input="searchInvoice" v-model="search" placeholder="Search Invoice"></b-input>
                          <span class="text-danger" v-if="searchError">Enter at least 4 characters</span>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <label>Status</label>
                          <select name="" class="form-control" v-model="status"
                                  @change="fetchDriverExtFiles"
                          >
                            <option value="pending">view pending files</option>
                            <option value="accepted">view accepted files</option>
                            <option value="declined">view declined files</option>
                            <option value="approved">view approved files</option>
                            <option value="unclear">view unclear files</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label>From</label>
                          <b-form-datepicker id="start-datepicker"
                                             @input="fetchDriverExtFiles"
                                             v-model="startDate"></b-form-datepicker>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label>To</label>
                          <b-form-datepicker id="end-datepicker"
                                             @input="fetchDriverExtFiles"
                                             v-model="endDate"></b-form-datepicker>
<!--                         -->
                        </div>
                      </div>
                    </div>
                    <hr>
                    <div class="row">

                      <template v-if="driverExtFiles.length === 0"> <h4>No receipts {{status}} from {{formattedDate(startDate)}} to {{formattedDate(endDate)}}</h4></template>

                      <template v-for="(file, index) in driverExtFiles">
                        <div class="col-sm-3" @click="onExternalFileTapped(file, index)">
                          <div :class="{'selected': selectedExtFileIndex === index}">
                            <b-img
                                thumbnail
                                :src="file.filePath"
                                width="200"
                                height="400"
                                style="object-fit: contain"
                                alt="image"
                            ></b-img>
                            <p class="mb-0">
                              <code class="mb-2">{{file['createdAt']}}</code>
                              <br>
                              <span class="badge p-1"
                                :class="{'badge-warning': (file.current_status !== 'approved' && file.current_status !== 'accepted'), 'badge-success': (file.current_status === 'approved' || file.current_status === 'accepted')}"
                              >{{file.current_status}}</span>
                              <template v-if="file['countPendingMessagesFromDriver'] > 0">
                                <span class="badge badge-pill badge-success float-right mb-2 mr-2"> {{file['countPendingMessagesFromDriver']}}</span>
                              </template>
                            </p>
                          </div>
                        </div>
                      </template>

                    </div>

                  </div>

                  <!--   Selected file details -->
                  <template v-if="selectedExtFile">

                    <hr>

                    <div class="mt-4">

                      <div class="media mb-3 mt-1">
                        <div class="media-body">
                          <small class="float-right">{{selectedExtFile.createdAt}}</small>
                          <h6 class="m-0 font-14">{{selectedExtFile.file_display_name}}</h6>
<!--                          <small class="text-muted">From: {{driver.name}} {{driver.other_names}}</small>-->
                        </div>
                      </div>
                    </div>
                    <!--              Tapped Image should show here-->
                    <div class="row">
                      <div class="col-md-6">
<!--                        <b-img-->
<!--                            thumbnail-->
<!--                            :src="selectedExtFile.filePath"-->
<!--                            alt="image"-->
<!--                        ></b-img>-->

                        <image-magnifier
                            :src="selectedExtFile.filePath"
                            :zoom-src="selectedExtFile.filePath"
                            style="width: 100%;"
                            height="350"
                            zoom-width="500"
                            zoom-height="500"
                        >
                        </image-magnifier>


                      </div>
                      <div class="col-md-6">
                        <b-dropdown class="btn-group mr-1" size="sm" variant="light">
                          <template slot="button-content">
                            <i class="mdi mdi-dots-horizontal font-18"></i> Click for more actions
                            <i class="mdi mdi-chevron-down"></i>
                          </template>
                          <b-dropdown-item @click="openDriverChat">Chat driver</b-dropdown-item>
                          <b-dropdown-item @click="updateExternalFileStatus('accepted')">Accept receipt</b-dropdown-item>
                          <b-dropdown-item @click="updateExternalFileStatus('declined')">Decline receipt</b-dropdown-item>
                          <b-dropdown-item @click="updateExternalFileStatus('unclear')">Mark as unclear</b-dropdown-item>
                        </b-dropdown>
                        <table class="table mt-3">
                          <tr>
                            <td>Status </td>
                            <td class="text-right"><span class="badge badge-success p-1"
                                 :class="{'badge-warning': (selectedExtFile.current_status !== 'approved' && selectedExtFile.current_status !== 'accepted'), 'badge-success': (selectedExtFile.current_status === 'approved' || selectedExtFile.current_status === 'accepted' )}"
                            >{{selectedExtFile.current_status}}</span></td>
                          </tr>
                          <tr>
                            <td>Status Assigned by </td>
                            <td class="text-right"> {{ (selectedExtFile.statusUpdatedBy && (selectedExtFile.statusUpdatedBy !== 'N/A'))  ? selectedExtFile.statusUpdatedBy.name : 'N/A'}} </td>
                          </tr>
                          <tr>
                            <td>Status Assigned on </td>
                            <td class="text-right">{{selectedExtFile.current_status !== 'pending' && selectedExtFile.statusUpdatedDate ?  selectedExtFile.statusUpdatedDate : 'N/A'}}</td>
                          </tr>
                          <tr>
                            <td>Comment</td>
                            <td class="text-right"> {{selectedExtFile['current_status_comment'] || 'N/A'}} </td>
                          </tr>
                          <template v-if="selectedExtFile['current_status'] === 'accepted' && !selectedExtFile['data_on_ext_receipts']">
                            <tr>
                              <td colspan="2">
                                <h4 class="text-bold card-title text-danger">Please save the receipt information below</h4>
                              </td>
                            </tr>
                          </template>
                        </table>

                      </div>
                    </div><hr />
                    <form @submit.prevent="handleSubmit" method="post" action="#">
                      <h5 class="font-18">
                        Enter receipt info
                      </h5>
                      <div class="mt-3">
                        <h6 class="m-0 font-14">Basic Information</h6>
                        <hr>
                        <div class="row">
                          <div class="col-md-4">
                            <div class="form-group">
                              <label for="invoice_number">Invoice number</label>
                              <input type="text" class="form-control" id="invoice_number" placeholder="Enter invoice number here"
                                     v-model="form.invoiceNumber"
                              >
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label for="invoice_date">Date on Invoice</label>
                              <input type="date" class="form-control" id="invoice_date" placeholder="Enter date on the invoice"
                                     v-model="form.invoiceDate"
                                     required>
                            </div>
                          </div>

                          <div class="col-md-4">
                            <div class="form-group">
                              <label for="invoice_place_name">Name of place</label>
                              <input type="text" class="form-control" id="invoice_place_name" placeholder="Enter place here"
                                     v-model="form.invoicePlace"
                                     required
                              >
                            </div>
                          </div>

                        </div>
                        <div class="row">
                          <div class="col-md-4">
                            <div class="form-group">
                              <label for="invoice_number">Auth code</label>
                              <input type="text" class="form-control"  placeholder="Enter authorization code here"
                                     v-model="form.authCode"
                              >
                            </div>
                          </div>
                        </div>

                        <template v-if="configuredFields">

                          <h6 class="m-0 font-14 mt-3">Fill the form below for financial administration & Weekly payment</h6>
                          <hr>

                            <div class="row">

                              <template v-for="(field, index) in configuredFields.calculatedFields">
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <label :for="'calc-field-'+index">{{field.fieldName}}</label>
                                      <input type="text" class="form-control" :id="'calc-field-'+index" :placeholder="'Enter '+field.fieldName+' here'"
                                             v-model="field['fieldValue']"
                                             required
                                      >
                                    </div>
                                  </div>
                              </template>

                            </div>


                        </template>

                        <hr>

                        <div class="form-group">
                          <b-form-checkbox
                              v-model="form.lateReceiptSubmission"
                              switch
                          >
                            Late submission
                          </b-form-checkbox>

                        </div>

                        <hr>

                        <!--                pick this from the fields configured in the external cost -->
                        <div class="form-group">
                          <button type="submit" class="btn btn-danger px-2 mr-2" v-if="showSaveReceiptBtn"> <i class="fa fa-save"></i> Save details </button>
                          <button type="button" class="btn btn-success px-2" v-if="showApproveReceiptBtn" @click="approveReceiptInfo"> <i class="fa fa-check-circle"></i> Approve receipt info (For Super Admin Only)</button>
                        </div>
                        <b-card>
                          <h4 class="card-title">Side note</h4>
                          <p class="text-danger"> * you can only save this receipt once the receipt is accepted </p>
                          <p class="text-danger"> * super admin can only approve details once the receipt has been saved </p>
                        </b-card>


                      </div>
                    </form>
                  </template>

                  <!--  End of selected file details -->

                </div>

              </template>


            </div >
            <div v-if="showChat && driver && selectedExtFile" class="inbox-rightbar">
              <chat-on-receipts :driver="driver" :file="selectedExtFile" @onBackPressed="closeDriverChat"></chat-on-receipts>
            </div>
          </div>
          <!-- card -->
        </div>
        <!-- end Col-9 -->
      </div>

<!--     Comment on status change modal -->
      <template v-if="selectedExtFile">
<!--        selectedExtFile.file_display_name -->
        <b-modal id="status-comment-modal" @ok="submitExtFileStatusChange" :title="'Add Comment'">
          <textarea class="form-control" v-model="comment" name=""  cols="30" rows="10" placeholder="Type comment here ..."></textarea>
        </b-modal>
      </template>

    </div>
</template>

<script>
import Toolbar from "../f-administration/toolbar";
import Sidepanel from "../f-administration/sidepanel";
import { emailData } from "../f-administration/data-inbox";
import ChatOnReceipts from "./chat-on-receipts"
import moment from 'moment'
import Multiselect from "vue-multiselect";



import appConfig from "../../../../../app.config.json";
import {confirm} from "../../../../utils/functions";

/**
 * Read-email component
 */
export default {
  components: {
    Toolbar,
    // Sidepanel,
    Multiselect,
    ChatOnReceipts,
  },
  data() {
    return {
      drivers:[],
      searchError:false,
      selectedDrivers:[],
      emailData: emailData,
      search:'',
      showChat: false,
      driver: null,
      selectedExtFile: null,
      selectedExtFileIndex: 0,
      showApproveReceiptBtn: false,
      showSaveReceiptBtn: true,
      form: {
        lateReceiptSubmission: false,
        invoiceNumber: '',
        authCode: '',
        invoiceDate: '',
        invoicePlace: '',
      },
      startDate: moment().startOf('week').isoWeekday(1).toISOString(),
      endDate: moment().endOf('week').toISOString(),
      status: 'pending',
      previousStatus: 'pending',
      comment: '',
    };
  },
  computed: {
    driverExtFiles(){
      return this.$store.getters.GET_SELECTED_DRIVER_EXT_FILES
    },
    configuredFields(){
      const data =  this.$store.getters.GET_EXTERNAL_DATA_CONFIG_FIELDS;
      if(!data)
        return data;

      return {
        'calculatedFields': JSON.parse(data['calculated_fields']),
        'taxFields': JSON.parse(data['tax_fields']),
      }

    },
  },
  methods: {
    searchInvoice(){
      this.searchError=false;
      if(this.search.length>3){
        this.searchError=false;
        this.fetchDriverExtFiles();
      }else if(this.search.length==0){
        this.searchError=false;
        this.fetchDriverExtFiles();
      }
      else{
        this.searchError=true;
      }
    },
    openDriverChat(){
      this.showChat = true
    },
    closeDriverChat(){
      this.showChat = false
    },
    taxOfConfiguredField(configuredField){
      return this.configuredFields['taxFields'].find(e => e.refFieldName === configuredField.fieldName)
    },
    formattedDate(value){
      return moment(value).format('MMMM Do YYYY');
    },

    approveReceiptInfo(){
      if(!this.selectedExtFile){
        this.$store.dispatch('error', {message: 'Please select a receipt', showSwal: true})
        return;
      }
      confirm("Final approval of the selected receipt", () => {
        this.$store.dispatch('approveExternalFileStatus', this.selectedExtFile['id']).then(() => {
          this.fetchDriverExtFiles();
        })
      })
    },

    getDrivers(showLoader = true){
      this.$store.dispatch('getDrivers', {limit: 1000, status: this.status, showLoader: showLoader}).then((res)=>{
        this.drivers = res.extra;
      });
    },

     handleSubmit(){
      console.log('form: ', this.configuredFields['calculatedFields'])
       confirm('save receipt', () => {
         const request = {
           'calculated_fields': this.configuredFields['calculatedFields'],
           'data_on_receipt': this.form,
           'invoice_number': this.form.invoiceNumber,
           'auth_code': this.form.authCode,
         }
         console.log('request', request)

         this.$store.dispatch('saveDataOnExternalReceipts', {
           fileId: this.selectedExtFile['id'],
           request
         }).then(() => {
           this.fetchDriverExtFiles()
         })


       })
     },

    onExternalFileTapped(file, index){
      this.selectedExtFile = file;
      this.selectedExtFileIndex = index;
    },

    onDriverSelected(driver){
      console.log('driver: ', driver);

      this.startDate = moment().startOf('week').isoWeekday(1).toISOString();
      this.endDate = moment().endOf('week').toISOString();
      this.selectedExtFile = null;
      this.selectedExtFileIndex = 0;
      this.showChat =  false;

      this.driver = driver;


    },


    fetchDriverExtFiles(showLoader = true){

      // if(!this.driver){
      //   return;
      // }

      this.$store.dispatch('fetchAllUploadedExternalFiles', {
        // userId: this.driver.id,
        showLoader: showLoader,
        request: {
          search:this.search,
          drivers: this.selectedDrivers,
          start_date: this.startDate,
          end_date: this.endDate,
          status: this.status
        }
      }).then(() => {
        // if(this.driverExtFiles) {
          this.onExternalFileTapped(this.driverExtFiles[this.selectedExtFileIndex], this.selectedExtFileIndex)
        // }
      });
    },

    updateExternalFileStatus(newStatus, requestComment = true){
      /// call modal for admin to add comment .....
      this.previousStatus = this.status;
      this.status = newStatus;
      if(newStatus === "accepted"){
        this.comment = "ACCEPTED";
      }else{
        this.comment = '';
      }
      if(requestComment){
        this.$bvModal.show('status-comment-modal')
        return;
      }

      this.submitExtFileStatusChange();

    },

    submitExtFileStatusChange(){

      this.$store.dispatch('updateExternalFileStatus', {
        fileId: this.selectedExtFile.id,
        request: {
          status: this.status,
          comment: this.comment,
        }
      }).then(() => {
        this.status = this.previousStatus;
        this.fetchDriverExtFiles()
      });
    },

    onFileSelected(value){
      if(value){
        let dataOnReceipts = value['data_on_ext_receipts'];
        console.log('current status', value['current_status'])
        if(value['current_status'] === 'accepted'){
          this.showSaveReceiptBtn = true
        }else{
          this.showSaveReceiptBtn = false
        }

        if(dataOnReceipts){
          //"{\"lateReceiptSubmission\":false,\"invoiceNumber\":\"123466\",\"invoiceDate\":\"2021-11-13\",\"invoicePlace\":\"Accra\"}"
          dataOnReceipts = JSON.parse(dataOnReceipts);
          this.form.invoiceNumber = dataOnReceipts['invoiceNumber']
          this.form.authCode = dataOnReceipts['authCode']
          this.form.lateReceiptSubmission = dataOnReceipts['lateReceiptSubmission']
          this.form.invoiceDate = dataOnReceipts['invoiceDate']
          this.form.invoicePlace = dataOnReceipts['invoicePlace']
          this.showApproveReceiptBtn = true;

        }else{

          this.form.invoiceNumber = ''
          this.form.authCode = ''
          this.form.lateReceiptSubmission = ''
          this.form.invoiceDate = ''
          this.form.invoicePlace = ''
          this.showApproveReceiptBtn = false
        }

        /// calculated taxes
        let calculated_and_tax_fields = value['calculated_and_tax_fields']
        if(calculated_and_tax_fields){
          calculated_and_tax_fields = JSON.parse(calculated_and_tax_fields)
          //  "[{"fieldName":"Brandstof","fieldType":"cost","weeklyPayment":true,"fAdministration":true,"fieldValue":"80.99","tax":{"refFieldName":"Brandstof","refFieldType":"cost","taxType":"upper","formula":"ref\/121*21","weeklyPayment":false,"fAdministration":true,"value":14.056115702479337}}]"
          //   this.configuredFields.calculatedFields.findIndex(e => e.)
          calculated_and_tax_fields.forEach(d => {
            const index = this.configuredFields.calculatedFields.findIndex(e => e.fieldName === d.fieldName)
            this.configuredFields.calculatedFields[index]['fieldValue'] = d['fieldValue']
          })
        }else{
          const data =  this.$store.getters.GET_EXTERNAL_DATA_CONFIG_FIELDS;
          if(!data){
            this.$store.dispatch('error', {message: 'Configurations for receipt fields has not been done', showSwal: true})
            return
          }
          this.configuredFields.calculatedFields = JSON.parse(data['calculated_fields'])

        }

        if(dataOnReceipts && calculated_and_tax_fields && (value['current_status'] === 'approved')){
          this.showApproveReceiptBtn = false;
        }


      }
    }


  },
  watch: {
    // showChat: function (value){
    //   if(!value){
    //     const payload = {
    //       type: 'receipt',
    //       id: this.selectedExtFile['chat_id']
    //     }
    //     this.$store.dispatch('setNotificationMessagesAsRead', payload)
    //   }
    // },
    '$store.getters.GET_RECEIPT_MESSAGES': function (value){
      this.fetchDriverExtFiles(false)
    },
    driver: function(){
      console.log('fetch external files ....')
      this.fetchDriverExtFiles();
    },
    selectedExtFile: function (value){
      console.log('selectedFile:',value);
      this.onFileSelected(value)
    },
    // '$store.getters.GET_SELECTED_DRIVER_EXT_FILES': function (){
    //   if(this.selectedExtFile){
    //     const value = this.selectedExtFile;
    //     this.onFileSelected(value)
    //   }
    // }
  },
  created() {
    this.$store.dispatch('getExternalDataConfigFields')
    this.$store.dispatch('setPageTitle', 'All Receipts')
    this.fetchDriverExtFiles(false);
    this.getDrivers(true);


  },
};
</script>

<style>
  .selected{
    border: 2px solid #EE4C4A;
    padding: 5px;
    border-radius: 5px;
  }
</style>

